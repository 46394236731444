import ProfilesRoutes from "./profiles";
import SectionsRoutes from "./sections";
import OptionsRoutes from "./options";
import DocumentsRoutes from "./document";

export default {
  path: "ajustes-formulario",
  name: "settings-form",
  redirect: { name: "settings-form-sections" },
  meta: {
    title: "Formulario",
    nav: true,
    settings: true,
    subject: "settings",
    action: "read",
    icon: "faFileInvoice",
  },
  component: () => import("@/views/layout/VRouterView"),
  children: [SectionsRoutes, ProfilesRoutes, OptionsRoutes, DocumentsRoutes],
};
